import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ListItems from 'components/Menu/listItems'
import {
  CSSTransition
} from 'react-transition-group'
import styled from '@emotion/styled'
import { css } from "@emotion/core"
import { useTranslation } from "react-i18next"

const Header = styled.h3`
  color: white;
  line-height: 3;
  position: relative;
  cursor:pointer;
  font-weight: 300;
  font-size: 1.1rem;

  &:hover:before {
    opacity: 1;
    width: 80%;
  }

  &:before {
    z-index: 100;
    content: "";
    position: absolute;
    bottom: 3rem;
    width: 0px;
    height: 3px;
    margin: 2px 0 0;
    transition: all 0.8s ease-in-out;
    opacity: 0;
    left: 0;
    background-color: white;
  }

  &:hover:after {
    opacity: 0;
    width: 0px;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 3rem;
    width: 80%;
    height: 3px;
    margin: 2px 0 0;
    opacity: 1;
    left: 0;
    background-color: white;
  }
`

const ListHeader = (props) => {
  const { t, i18n } = useTranslation()

  return (
    <Header
      className="expandable-listview_listHeader" 
      onClick={props.handleClick(props.headerIndex)}
    >
      {t(`navbar.${props.header}`)}
    </Header> 
  )
}

export default class MenuGroup extends Component {
  state = {
    isOpened : {
      0: true,
      1: false,
      2: false
    },
    myItems: []
  }

  handleClick = param => e => {
    console.log(param)
    const headerIndex = param
    this.setState(prevState => ({
        isOpened: {
          ...prevState.isOpened,
          [headerIndex]: !prevState.isOpened[headerIndex]
        }
      })
    )
  }

  render() {
    const { headerIndex, listGroup } = this.props
    const { isOpened } = this.state
    const header = listGroup['headerName']

    return (
      <div css={css`
          text-transform: uppercase;
          padding-bottom: 25px;
        `}>
        <ListHeader
          header={header}
          headerIndex={headerIndex}
          handleClick={this.handleClick}
        />
        <CSSTransition
          classNames="list-transition"
          in={isOpened[headerIndex]}
          timeout={2000}
          unmountOnExit
          appear
        >
          <ListItems 
            items={listGroup['items']}
          />
        </CSSTransition>
      </div>
    )
  }
}

MenuGroup.propTypes = {
  headerIndex: PropTypes.number.isRequired
}
