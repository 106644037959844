export const MenuData = [
    {
      headerName: 'homeHeader',
      isOpened: true,
      // isReactComponent: false,
      items: [{
          id: 1,
          title: 'about',
          url: '/'
        }, 
        {
          id: 2,
          title: 'experience',
          url: '/experience'
        },
        {
          id: 3,
          title: 'project',
          url: '/projects'
        },
        {
          id: 4,
          title: 'contact',
          url: '/contact'
        }],
      height: 120
    }
  ]