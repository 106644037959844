import React from "react"
import PropTypes from "prop-types"
import { css, Global } from "@emotion/core"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { withIntl } from 'src/i18n'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'

import NavBar from "./navBar"
import theme from "./theme"
import fontFiles from "../fonts/fonts"

library.add(faBars, faAngleDown, faAngleUp)

type PropsT = {
  children: any,
  i18n: any,
  t: (key: string) => string,
  pageContext: any
};

// Create styles for the Global component
const globalStyles = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  @font-face {
    font-family: 'Rajdhani';
    font-style: normal;
    font-weight: normal;
    src: local("Rajdhani"), url(${fontFiles.RajdhaniTTF}) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: 'Rajdhani-Bold';
    font-style: normal;
    font-weight: bold;
    src: local("Rajdhani-Bold"), url(${fontFiles.RajdhaniBoldTTF}) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: bold;
    src: local("Montserrat-Bold"), url(${fontFiles.MontserratBoldTTF}) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: local('Material Icons'),
      local("MaterialIcons-Regular"),
      url(${fontFiles.MaterialIconsTTF}) format("truetype");
  }
  html,
  body {
    font-size: 1em;
    font-family: 'Rajdhani', sans-serif;
  }
  h1 {
    color: #ae68bc;
    font-family: 'Montserrat-Bold', sans-serif;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin: 1.3rem 2vw;
    font-size: calc(1em + 3.2vw);
    text-shadow: 7px 7px 9px #e4c5ea;
  }
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
  
    /* Support for IE. */
    font-feature-settings: 'liga';
  }
`

const Layout = ({ children, t, i18n, pageContext }: PropsT) => {
  //console.log('TEST: ', t)
  
  return (
    <MuiThemeProvider theme={theme}>
    <div css={css`
        display: flex;
        flex-direction: row;
        height: 100%;
        @media (min-width: 320px) and (max-width: 480px) {
          flex-direction: column;
        } 
      `}>
      <Global styles={globalStyles} />
      <NavBar />
      <main css={css`
        flex: 1;
      `}>
        {children}
      </main>
    </div>
    </MuiThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withIntl()(Layout)
