import RajdhaniTTF from "./Rajdhani-Regular.ttf"
import RajdhaniBoldTTF from "./Rajdhani-Bold.ttf"
import MontserratBoldTTF from "./Montserrat-Bold.ttf"
import MaterialIconsTTF from "./MaterialIcons-Regular.ttf"

export default {
  RajdhaniTTF,
  RajdhaniBoldTTF,
  MontserratBoldTTF,
  MaterialIconsTTF
}