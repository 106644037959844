import React, { useState } from 'react'
import { Link } from 'gatsby'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import cnLogo from "images/cn.png"
import twLogo from "images/tw.png"
import enLogo from "images/en.png"

const useStyles = makeStyles({
  nestedListRoot: {
    paddingLeft: '40px'
  },
  listItemMenuRoot: {
    color: 'hotpink'
  }
})

const ListItemRegular = (props) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const { name, toggleDrawer } = props
  const transName = t(`navbar.${name}`)

  const getPageLink = () => {
    switch(name) {
      case 'about':
        return '/';
      case 'experience':
      case 'contact':
        return `/${name}`
      case 'project':
        return '/projects';
    }
  }

  return (
    <ListItem
      component={Link}
      to={getPageLink()}
      key={name}
      onClick={(e) => toggleDrawer()}
      classes={{ 
        root: classes.listItemMenuRoot
      }}
    >
      <ListItemText primary={transName} />
    </ListItem>
  )
}

const ListItemWithIcon = (props) => {
  const itemName = props.name
  const { handleI18nChange, toggleDrawer } = props
  const { t, i18n } = useTranslation()
  const transName = t(`navbar.${itemName}`)

  const getLanguageLogo = () => {
    switch(itemName) {
      case 'Simplified Chinese':
        return cnLogo;
      case 'Traditional Chinese':
        return twLogo;
      case 'English':
        return enLogo;
    }
  }

  const getLanguageCode = () => {
    switch(itemName) {
      case 'Simplified Chinese':
        return 'cn';
      case 'Traditional Chinese':
        return 'zh_TW';
      case 'English':
        return 'en';
    }
  }
  
  const handleClickOnLanguage = (e) => {
    const code = getLanguageCode()
    toggleDrawer()
    handleI18nChange(e, code)
  }

  return (
    <ListItem
      button
      key={itemName}
      onClick={handleClickOnLanguage} >
      <img src={getLanguageLogo()} alt="CN" />
      <ListItemText primary={transName} />
    </ListItem>
  )
}

const ListGroup = (props) => {
  const classes = useStyles()
  const { subItems, groupHeader, handleI18nChange, toggleDrawer } = props
  const [open, setOpen] = useState(true)
  const handleClick = () => { setOpen(!open) }
  const { t, i18n } = useTranslation()
  let header = t(`navbar.${groupHeader}`)

  return (
    <List>
      <ListItem button onClick={handleClick}>
        <ListItemText
          primary={header} />
        { open ? <FontAwesomeIcon icon={"angle-up"} /> : <FontAwesomeIcon icon={"angle-down"} /> }
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div" 
          disablePadding
          classes={{ 
            root: classes.nestedListRoot
          }}
        >
        {subItems.map((text, index) => {
          if(groupHeader === "languageHeader") {
            return (
              <ListItemWithIcon 
                name={text}
                key={index}
                handleI18nChange={handleI18nChange}
                toggleDrawer={toggleDrawer}
              />
            )
          } else {
            return (
              <ListItemRegular
                name={text}
                key={index}
                toggleDrawer={toggleDrawer}
              />
            )
          }
        })}
        </List>
      </Collapse>
    </List>
  )
}

const MobileMenu = (props) => {
  const homeSubItems = ['about', 'experience', 'project', 'contact']
  const languageSubItems = ['English', 'Traditional Chinese', 'Simplified Chinese']
  const { toggleDrawer } = props

  return (
    <div
      role="presentation"
    >
      <ListGroup
        groupHeader={"homeHeader"}
        subItems={homeSubItems}
        toggleDrawer={toggleDrawer}
      />
      <Divider />
      <ListGroup
        groupHeader={"languageHeader"}
        subItems={languageSubItems}
        toggleDrawer={toggleDrawer}
        handleI18nChange={props.handleI18nChange}  //TODO: make language change work
      />
    </div>
  )
}

export default MobileMenu