import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { css } from "@emotion/core"
import { withTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import './menuStyles.css'

const ulStyle = css`
  list-style-type: none;
  margin: 0;
  padding-left: 35px;
  font-size: 1.1rem;
  font-weight: 300;
`

const linkStyle = css`
  color: white;
  line-height: 3;
  text-decoration: none;
`

const StyledListItem = styled.li`
  position: relative;

  &:hover {
    color: #273C57;
    font-family: "Rajdhani-Bold";
  }

  &:before {
    z-index: 100;
    content: "";
    position: absolute;
    bottom: 2.3rem;
    width: 100px;
    height: 1px;
    margin: 2px 0 0;
    background-color: white;
  }

  &:hover:before {
    background-color: #273C57;
    width: 150%;
    height: 2px;
  }
`

class ListItems extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired
  }

  state = { 
    menuItems: this.props.items
  }

  render () {
    const { menuItems } = this.state
    const { t } = this.props

    return (
        <ul css={ulStyle}>
          {
            menuItems.map(({ id, title, url }) => (
              <AniLink 
                swipe direction="up" 
                to={url}
                key={id}
                css={linkStyle}
              >
                <StyledListItem>
                  {t(`navbar.${title}`)}
                </StyledListItem>
              </AniLink>
              )
            )
          }
        </ul>
    )
  }
}

export default withTranslation()(ListItems);