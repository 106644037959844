import PropTypes from "prop-types"
import React, { useState } from "react"
import logo from "images/cathy_logo_new.png"
import Drawer from '@material-ui/core/Drawer'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'


import ExpandableMenu from "components/Menu/expandableMenu"
import MobileMenu from "components/MobileMenu/mobileMenu"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { css } from "@emotion/core"
import { makeStyles } from '@material-ui/core/styles'
import { withContext } from './PageContext'
//import { withIntl } from 'src/i18n'
import i18next from 'src/i18n/config'

import cnLogo from "images/cn.png"
import twLogo from "images/tw.png"
import enLogo from "images/en.png"

const useStyles = makeStyles({
  paperAnchorTop: {
    top: '75px'
  },
  menuPaperRoot: {
    backgroundColor: 'transparent'
  },
  selectFocus: {
    "&:focus": {
      background: 'transparent'
    }
  }
})

const LanguageDropDown = (props) => {
  const classes = useStyles()

  return(
    <Select
      value={props.values.language}
      onChange={props.handleChange}
      disableUnderline
      inputProps={{
        name: 'language',
        id: 'age-simple'
      }}
      MenuProps={{
        classes: {
          //paper: classes.paperRoot
        }
      }}
      classes={{
        select: classes.selectFocus
      }}
    >
      <MenuItem value={'en'}><img src={enLogo} alt="EN" /></MenuItem>
      <MenuItem value={'zh-Hant'}><img src={twLogo} alt="TW" /></MenuItem>
      <MenuItem value={'zh-Hans'}><img src={cnLogo} alt="CN" /></MenuItem>
    </Select>
  )
}

const NavBar = (props) => {
  const classes = useStyles()
  const [isDrawerOpened, setDrawer] = useState(false)
  const [values, setValues] = useState({
    language: 'en',
    name: 'hai',
  });

  function handleChange(event, lanCode = null) {
    // console.log('click language menu!', event)
    if (lanCode) {
      console.log(lanCode)
      i18next.changeLanguage(lanCode)
    } else {
      i18next.changeLanguage(event.target.value)

      setValues(oldValues => ({
        ...oldValues,
        [event.target.name]: event.target.value,
      }));
    }
  }

  function toggleDrawer() {
    setDrawer(!isDrawerOpened)
  }


  return (
    <div
      css={css`
        flex: 0 0 200px;
        background: #ae68bc;
        @media (min-width: 320px) and (max-width: 480px) {
          position: fixed;
          z-index: 500;
          width: 100%;
        } 
      `}
    >
      <div
        css={css`
          height: 100vh;
          padding: 40px 20px 30px;
          @media (min-width: 320px) and (max-width: 480px) {
            display: none;
          }       
        `}
      >
        <div>
          <img 
            css={css`
              height: 70px;
              margin: 0 auto 12vh;
              display: block;
            `}
            src={logo} alt="CathyH Logo" />
        </div>
        <ExpandableMenu />
        <div
          css={css`
            position: absolute;
            bottom: 40px;
            width: 160px;
            text-align:center;
          `}
        >
          <LanguageDropDown 
            values={values}
            handleChange={(e) => handleChange(e)}
          />
        </div>
      </div>
  
      {/* NavBar look on mobile */}
      <div
        css={css`
          height: 75px;
          margin: auto 0;
          display: inline-block;
          position: relative;
          @media (min-width: 768px) {
            display: none;
          }      
        `}
      >
        <FontAwesomeIcon
          icon={"bars"}
          size="2x"
          css={css`
            color: white;
            top: 23px;
            left: 20px;
            position: absolute;
            z-index: 1000;
          `}
          onClick={toggleDrawer}
        />
        <div css={css`
          width: 100vw;
          text-align: center;
          top: 15px;
          position: absolute;
        `}>
          <img
            css={css`
              height: 52px;
            `}
            src={logo} alt="CathyH Logo" />
        </div>
        <Drawer 
          anchor="top"
          open={isDrawerOpened}
          onClose={toggleDrawer}
          classes={{ 
            paperAnchorTop: classes.paperAnchorTop
          }}
          css={css`
            @media (min-width: 768px) {
              display: none;
            }      
          `}
        >
          <MobileMenu 
            handleI18nChange={handleChange}
            toggleDrawer={toggleDrawer}
          />
        </Drawer>
      </div>
    </div>
  )
}

export default withContext(NavBar)
