
import { createMuiTheme } from "@material-ui/core/styles"

const contactFormPurple = '#7b1fa2'

export default createMuiTheme({
  overrides: {
    MuiTypography: {
      body1: {
        fontFamily: 'Rajdhani, sans-serif'
      }
    },
    MuiInputBase: {
      root: {
        fontFamily: 'sans-serif'
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Rajdhani, sans-serif',
        '&$focused': {
          color: contactFormPurple
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline ': {
          borderColor: contactFormPurple,
        },
        '&:hover': {
          backgroundColor: 'white',
          borderColor: contactFormPurple,
        }
      }
    },
    MuiFormControl: {
      root: {
        "&.MessageCathy": {
          width: '60%'
        }
      }
    }
  }
});