import PropTypes from "prop-types"
import React, { Component } from "react"
import { MenuData } from "utils/const"
import MenuGroup from "components/Menu/menuGroup"

class ExpandableMenu extends Component {

	render() {
		return(
			<div className="expandable_menu_container">
				{
					MenuData.map((listGroup, idx) => {
						return (
              <MenuGroup
                key={idx}
                headerIndex={idx}
                listGroup={listGroup}
              />
						)
					})
        }
			</div>
		)

	}
}

ExpandableMenu.propTypes = {
  data: PropTypes.array
}

export default ExpandableMenu